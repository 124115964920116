var __WEBPACK_NAMESPACE_OBJECT__ = {
  "mode": "compressed",
  "staticDomainPrefix": "//static.hsappstatic.net",
  "bender": {
    "depVersions": {
      "react-rhumb": "static-1.13192"
    },
    "depPathPrefixes": {
      "react-rhumb": "/react-rhumb/static-1.13192"
    },
    "project": "react-rhumb",
    "staticDomain": "//static.hsappstatic.net",
    "staticDomainPrefix": "//static.hsappstatic.net"
  }
};